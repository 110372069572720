import React from "react";
import get from "lodash/get";

import { graphql } from "gatsby";

import Layout from "../components/global/layout";
import Container from "../components/global/container";
import InnerPageHeader from "../components/inner-page-header/inner-page-header";

class Jobs extends React.Component {
    render() {
        const meta = get(this, "props.data.meta");
        const { location } = this.props;

        return (
            <Layout
                title={`Jobs | ${meta.siteMetadata.title}`}
                location={location}
            >
                <InnerPageHeader pageTitle="Jobs" />
                <article>
                    <section className="py-60 background-black text-white">
                        <Container>
                            <div className="row">
                                <div className="col-12">
                                    <p>
                                        Unifyd is the industry standard yield
                                        generator protocol combining DeFi
                                        investment products with the power of
                                        AI. With Unifyd, anyone can access advanced
                                        interest bearing DeFi investments
                                        powered by smart algorithems to maximize
                                        their profits. Managed by a global,
                                        decentralized community, Unifyd is
                                        introducing a fairer model for
                                        contracts. Unifyd is trusted by hundreds of
                                        organizations—from global enterprises to
                                        projects at the forefront of the
                                        blockchain economy to make DeFi
                                        investments accessible to anyone, around
                                        world.
                                        <br />
                                        <br />
                                        All roles with Unifyd Labs are globally
                                        remote based. We encourage you to apply
                                        regardless of your location.
                                        <br />
                                        We are a fully distributed team and have
                                        the tools and benefits to support you in
                                        your remote work environment.
                                    </p>

                                    <hr className="my-60" />

                                    <h2 className="font-weight-bold font-size-md mb-10">
                                        Security Engineer
                                    </h2>
                                    <p>REMOTE / ENGINEERING / FULL-TIME</p>
                                    <br />
                                    <br />

                                    <h3 className="font-weight-medium font-size-sm mb-20 decoration-underline">
                                        Your Impact
                                    </h3>
                                    <ul className="mb-20">
                                        <li className="mb-10">
                                            Oversee our entire security plan
                                            while influencing and driving
                                            adoption across the team
                                        </li>
                                        <li className="mb-10">
                                            Partner closely with our founders
                                            and engineering team to develop,
                                            design and implement security
                                            solutions
                                        </li>
                                        <li className="mb-10">
                                            Lead our risk program and establish
                                            best practices that will impact the
                                            security and long term success of
                                            Unifyd
                                        </li>
                                        <li className="mb-10">
                                            Create independently, or collaborate
                                            with the engineering team, to write
                                            and ship security features
                                        </li>
                                        <li>
                                            Research, document, and implement
                                            solutions that will ensure long term
                                            success of the Unifyd ecosystem
                                        </li>
                                    </ul>

                                    <h3 className="font-weight-medium font-size-sm mb-20 decoration-underline">
                                        Requirements
                                    </h3>
                                    <ul className="mb-20">
                                        <li className="mb-10">
                                            Software engineering background
                                            (CS/SE degree or equivalent
                                            experience)
                                        </li>
                                        <li className="mb-10">
                                            5 years of working experience in
                                            software engineering
                                        </li>
                                        <li className="mb-10">
                                            3 years of working experience in a
                                            related cybersecurity role
                                        </li>
                                        <li className="mb-10">
                                            Experience with developing security
                                            plans, processes and controls
                                        </li>
                                        <li className="mb-10">
                                            Experience with discovering
                                            vulnerabilities at design/system
                                            level
                                        </li>
                                        <li className="mb-10">
                                            Experience across common
                                            vulnerability classes relevant to
                                            our tech stack
                                        </li>
                                        <li className="mb-10">
                                            Proven ability to communicate across
                                            roles to drive security mindset
                                            across the company
                                        </li>
                                        <li className="mb-10">
                                            Good knowledge of operational
                                            security
                                        </li>
                                        <li>
                                            Experience with monitoring and
                                            alerting
                                        </li>
                                        <li className="mb-10">
                                            Experience working in an early
                                            growth-stage company
                                        </li>
                                        <li className="mb-10">
                                            Focus on improving real-world
                                            security, not compliance
                                        </li>
                                        <li>
                                            Blockchain/cryptocurrency experience
                                        </li>
                                    </ul>

                                    <h3 className="font-weight-medium font-size-sm mb-20 decoration-underline">
                                        Our Stack
                                    </h3>
                                    <p className="mb-20">
                                        Golang, TypeScript, Solidity, Postgres,
                                        Terraform, AWS
                                    </p>

                                    <p>
                                        Sounds like you? send your
                                        resume/cover-letter to{" "}
                                        <a mailto="jobs@unifyd.finance">
                                            jobs@unifyd.finance
                                        </a>
                                    </p>

                                    <hr className="my-60" />

                                    <h2 className="font-weight-bold font-size-md mb-10">
                                        Big Data Engineer
                                    </h2>
                                    <p>REMOTE / ENGINEERING / FULL-TIME</p>
                                    <br />
                                    <br />

                                    <h3 className="font-weight-medium font-size-sm mb-20 decoration-underline">
                                        Your Impact
                                    </h3>
                                    <ul className="mb-20">
                                        <li className="mb-10">
                                            Create and implementing scalable and
                                            reliable ETL/ELT pipelines and
                                            processes to ingest data from
                                            different data sources
                                        </li>
                                        <li className="mb-10">
                                            Maintain blockchain nodes
                                        </li>
                                        <li className="mb-10">
                                            Implement best in class CI/CD
                                            frameworks
                                        </li>
                                        <li className="mb-10">
                                            Facilitate near real-time data
                                            collection
                                        </li>
                                        <li className="mb-10">
                                            Own technical solutions for the Data
                                            Lake Infrastructure
                                        </li>
                                        <li>
                                            Collaborate and cooperate with other
                                            team members to fulfill the data
                                            needs
                                        </li>
                                    </ul>

                                    <h3 className="font-weight-medium font-size-sm mb-20 decoration-underline">
                                        Requirements
                                    </h3>
                                    <ul className="mb-20">
                                        <li className="mb-10">
                                            5+ years Python/Scala/Java
                                            development experience
                                        </li>
                                        <li className="mb-10">
                                            Experience of working with
                                            RestAPI/JSON-RPC
                                        </li>
                                        <li className="mb-10">
                                            Big data processing experience like
                                            Hadoop, Apache Spark or Apache Flink
                                        </li>
                                        <li className="mb-10">
                                            Experience building data pipelines
                                            using workflow management engines
                                            such as Airflow, Luigi, Prefect,
                                            Google Cloud Composer, AWS Step
                                            Functions, Azure Data Factory, UC4,
                                            Control-M
                                        </li>
                                        <li className="mb-10">
                                            3+ years experience of working on
                                            cloud or on-prem Big data/MPP
                                            platforms(AWS EMR, Azure HDInsight,
                                            GCP Dataflow/Dataproc, AWS Redshift,
                                            Azure Synapse or BigQuery etc.)
                                        </li>
                                        <li className="mb-10">
                                            GCP strongly preferred
                                        </li>
                                        <li className="mb-10">
                                            ElasticSearch preferred
                                        </li>
                                        <li>
                                            Experience with modern query engines
                                            such as Presto/Apache Impala etc.
                                        </li>
                                    </ul>

                                    <h3 className="font-weight-medium font-size-sm mb-20 decoration-underline">
                                        Desired Qualifications
                                    </h3>
                                    <ul className="mb-20">
                                        <li className="mb-10">
                                            Excitement for blockchain, Web 3.0,
                                            and similar decentralized
                                            technologies.
                                        </li>
                                        <li className="mb-10">
                                            Experience with GitHub Actions and
                                            self-hosted runners in particular.
                                        </li>
                                        <li className="mb-10">
                                            Experience working remotely in a
                                            distributed team.
                                        </li>
                                        <li>
                                            A strong desire to grow and
                                            challenge yourself. While this role
                                            is mainly focused on maintenance, we
                                            would expect you to constantly find
                                            ways to improve and automate
                                            services under your purview.
                                        </li>
                                    </ul>

                                    <p>
                                        Sounds like you? send your
                                        resume/cover-letter to{" "}
                                        <a mailto="jobs@unifyd.finance">
                                            jobs@unifyd.finance
                                        </a>
                                    </p>

                                    <hr className="my-60" />

                                    <h2 className="font-weight-bold font-size-md mb-10">
                                        Growth Marketer
                                    </h2>
                                    <p>REMOTE / MARKETING / FULL-TIME</p>
                                    <br />
                                    <br />

                                    <h3 className="font-weight-medium font-size-sm mb-20 decoration-underline">
                                        Your Impact
                                    </h3>
                                    <ul className="mb-20">
                                        <li className="mb-10">
                                            Create, develop, and manage Google
                                            Analytics events and goals to
                                            measure and improve top-of-funnel
                                            growth and movement through
                                            acquisition
                                        </li>
                                        <li className="mb-10">
                                            Implement a tag management system
                                            and new tools to better capture
                                            event tracking
                                        </li>
                                        <li className="mb-10">
                                            Measure and further our SEO strategy
                                            across properties to drive discovery
                                        </li>
                                        <li className="mb-10">
                                            Execute marketing campaigns and
                                            initiatives, following through to
                                            implementation
                                        </li>
                                        <li className="mb-10">
                                            Contribute to the editorial voice of
                                            Unifyd labs by writing content and copy
                                            for various growth initiatives,
                                            spanning owned, partner, and paid
                                            channels
                                        </li>
                                        <li className="mb-10">
                                            Research and experiment with the
                                            implementation of ads and
                                            sponsorships across social networks,
                                            search, podcasts, newsletters, and
                                            other channels
                                        </li>
                                        <li className="mb-10">
                                            Execute and develop our email
                                            strategy to engage with tens of
                                            thousands of people
                                        </li>
                                        <li>
                                            Design growth marketing experiments
                                            to optimize various aspects of our
                                            business
                                        </li>
                                    </ul>

                                    <h3 className="font-weight-medium font-size-sm mb-20 decoration-underline">
                                        Requirements
                                    </h3>
                                    <ul className="mb-20">
                                        <li className="mb-10">
                                            2-3+ years of experience in Google
                                            Analytics, SEO, user acquisition, or
                                            demand generation
                                        </li>
                                        <li className="mb-10">
                                            Strong analytical skill set with a
                                            demonstrated ability to think
                                            creatively{" "}
                                        </li>
                                        <li className="mb-10">
                                            Excellent writer and communicator
                                            with extreme attention to detail
                                        </li>
                                        <li className="mb-10">
                                            Comfortable operating independently
                                            and a keen ability to identify
                                            opportunities
                                        </li>
                                        <li className="mb-10">
                                            Familiarity with SQL, Adobe, Figma,
                                            Canva, HTML, CSS, and Mailchimp
                                            preferred
                                        </li>
                                        <li className="mb-10">
                                            Excellent analytical skills and the
                                            ability to interpret data, identify
                                            trends and analyze marketing and
                                            campaign effectiveness
                                        </li>
                                        <li className="mb-10">
                                            Must thrive in an environment of
                                            rapid change, moving targets,
                                            limited resources, and high
                                            intensity{" "}
                                        </li>
                                        <li>
                                            A self-starter mentality, able to
                                            work independently and proactively
                                            to resolve issues
                                        </li>
                                    </ul>

                                    <p>
                                        Sounds like you? send your
                                        resume/cover-letter to{" "}
                                        <a mailto="jobs@unifyd.finance">
                                            jobs@unifyd.finance
                                        </a>
                                    </p>

                                    <hr className="my-60" />

                                    <h2 className="font-weight-bold font-size-md mb-10">
                                        Community Evangelist
                                    </h2>
                                    <p>
                                        REMOTE / BUSINESS DEVELOPMENT /
                                        FULL-TIME
                                    </p>
                                    <br />
                                    <br />

                                    <h3 className="font-weight-medium font-size-sm mb-20 decoration-underline">
                                        Your Impact
                                    </h3>
                                    <ul className="mb-20">
                                        <li className="mb-10">
                                            Participate in discussions to
                                            articulate the benefits and uses
                                            cases of Unifyd solutions to relevant
                                            communities and projects.
                                        </li>
                                        <li className="mb-10">
                                            Actively advocate for Unifyd in the
                                            blockchain and Ethereum communities
                                            wherever they gather, online and
                                            offline.
                                        </li>
                                        <li className="mb-10">
                                            Be Unifyd’s representative in
                                            governance processes, and write
                                            technical proposals as needed to
                                            pitch Unifyd solutions.
                                        </li>
                                        <li className="mb-10">
                                            Develop deep functional product
                                            expertise of Unifyd.
                                        </li>
                                        <li>
                                            Gather insights about the use of our
                                            products from the blockchain
                                            ecosystem, and feed them back to our
                                            marketing and sales teams.
                                        </li>
                                    </ul>

                                    <h3 className="font-weight-medium font-size-sm mb-20 decoration-underline">
                                        Requirements
                                    </h3>
                                    <ul className="mb-20">
                                        <li className="mb-10">
                                            Deep knowledge of, or interest in,
                                            Unifyd.
                                        </li>
                                        <li className="mb-10">
                                            1+ years of experience in sales,
                                            preferably in a rapidly growing
                                            startup.
                                        </li>
                                        <li className="mb-10">
                                            Available 10-20 hours per week.
                                        </li>
                                        <li className="mb-10">
                                            Active participant in governance
                                            protocol communities and forums.
                                        </li>
                                        <li className="mb-10">
                                            Strong interpersonal skills with a
                                            sales-oriented mindset.
                                        </li>
                                        <li className="mb-10">
                                            Working knowledge of today’s major
                                            Blockchain technologies, players and
                                            major up-and-coming trends.
                                        </li>
                                        <li className="mb-10">
                                            Have a strong network in the
                                            blockchain ecosystem.
                                        </li>
                                        <li className="mb-10">
                                            Combination of technical acumen
                                            coupled with the ability to
                                            communicate the technical elements
                                            in a simple, effective manner;
                                            ability to translate complex
                                            technical concepts and technologies
                                            into strategic business and
                                            customer-centric value propositions
                                        </li>
                                        <li className="mb-10">
                                            Ability to establish trust with
                                            users and ability to influence key
                                            decision makers in the sales cycle.
                                        </li>
                                        <li className="mb-10">
                                            Proficiency in written and oral
                                            communications with a strong
                                            affinity for technical writing and
                                            proposals.
                                        </li>
                                        <li className="mb-10">
                                            Proactive operator who is confident
                                            to jump on opportunities as they
                                            arise.
                                        </li>
                                        <li>
                                            Passionate, self-starter with a
                                            can-do attitude. You're eager to
                                            jump in and get things done but
                                            don't get flustered in a fast-paced
                                            and often changing environment.
                                        </li>
                                    </ul>

                                    <p>
                                        Sounds like you? send your
                                        resume/cover-letter to{" "}
                                        <a mailto="jobs@unifyd.finance">
                                            jobs@unifyd.finance
                                        </a>
                                    </p>
                                </div>
                            </div>
                        </Container>
                    </section>
                </article>
            </Layout>
        );
    }
}

export default Jobs;

export const JobsQuery = graphql`
    query Jobs {
        meta: site {
            siteMetadata {
                brandName
                description
                fullBrandName
                title
                siteUrl
            }
        }
    }
`;
